import { WithTranslation } from 'react-i18next';

import { ProductSummaryShape } from 'lib/util/productSummaryShape';
import { CurrencyFormatter } from 'hooks/useCurrencyFormatter';

export const getDiscountText = (
  product: ProductSummaryShape,
  t: WithTranslation['t'],
  currencyFormatter: CurrencyFormatter
): string => {
  let discountText = '';
  if (product.bestDiscountPercent || product.bestDiscountGross) {
    discountText = t('{{discount}} OFF', {
      discount: product.bestDiscountPercent
        ? `${product.bestDiscountPercent}%`
        : currencyFormatter.format(product.bestDiscountGross),
    });
  }
  return discountText;
};
